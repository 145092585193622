<template>
  <div>
<v-form ref="forgotPasswordForm">
        <!-- <app-form-field v-model.trim="forgotPasswordObj.memberCode" :label="$t(`field.username`)" :hint="$t(`fieldHint.username`)" :rules="validator.usernameRules()"></app-form-field>
                      <app-form-field
                        v-model.trim="forgotPasswordObj.mobile"
                        :label="$t(`field.phoneNumber`)"
                        :hint="$t(`fieldHint.phoneNumber`)"
                        :rules="validator.mobileNumberRules('my')"
                        :prefix="stringFormat('+{0}', forgotPasswordObj.mobileNumberPrefix)"
                      ></app-form-field> -->
        <!-- <v-row no-gutters>
          <v-col cols=4>
            <v-select class="input-field mr-2" v-model="forgotPasswordObj.mobileNumberPrefix" item-text="text" item-value="value" :items="mobileNumberPrefixList" dense outlined></v-select>
          </v-col>
          <v-col cols=8>
            <v-text-field class="input-field elevation-0 hide-details" outlined color="navigator_active" :type="'text'">
            </v-text-field>
            <app-form-field :enabledLabel=false :solo="true" v-model.trim="forgotPasswordObj.mobile" :hint="$t(`fieldHint.fillUpHere`)" :rules="validator.usernameRules()"></app-form-field>
          </v-col>
        </v-row>
        <app-button :action="this.forgotPassword" :title="$t(`button.submit`)"></app-button>
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn height="auto" @click="this.forgotPassword" color="black" depressed class="primary-button text-capitalize reset-button pa-3 font-weight-bold yellow--text subtitle-1">{{ $t(`button.reset`) }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-6">
          <v-col cols="12">
            <v-divider class="dialog-divider"></v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols=12>
            <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
                              {{ $t(`message.dontHaveAccount`) }}</label>
          </v-col>
        </v-row>
        <v-row no-gutters class="pb-10">
          <v-col cols="12">
            <app-button :action="this.openRegister" :title="$t(`button.register`)"></app-button>
          </v-col>
        </v-row> -->
      </v-form>

  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
export default {
  name: 'resetPassword',
  created() {
    this.initializeResetPassword()
  },
  methods: {
    initializeResetPassword() {
      this.$router.push({
        name: ROUTE_NAME.HOME,
        params: {
          action: SHARED.RESET_PASSWORD,
          memberCode: this.$route.query.memberCode,
          contact: this.$route.query.contact,
          lang: this.$route.params.lang
        }
      })
    }
  }
}
</script>
